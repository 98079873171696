import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getLogData } from '../getLogData';
import { auditclass } from '../models/auditLog';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  obj = new auditclass();
  AdminName: any;
  impersonatedUserName: any;
  User: any;
  availityUser: any;
  availityUserId: any;
  constructor(private http: HttpClient, public commonService: CommonService) {
    this.obj.application = "PACT";
  }
  callApI(para: any) {

    this.obj.city = '';
    this.obj.clientbrowser = getLogData.getbroweser() as string;
    this.obj.clientip = this.commonService.getSessionStorage("clientip") as string;
    this.obj.continent = '';
    this.obj.country = '';
    this.obj.countrycode = '';
    this.obj.region = '';
    this.User = this.commonService.getLocalStorage('oktaUser');
    this.availityUser = this.commonService.getLocalStorage('availityUser');
    this.availityUserId = this.commonService.getLocalStorage('availityUserId');
    const firebaseuser = this.commonService.getLocalStorage('user');
    if (this.User?.additionalUserInfo?.profile?.firstName && this.User?.additionalUserInfo?.profile?.lastName) {
      this.AdminName = this.User?.additionalUserInfo?.profile?.firstName + " " + this.User?.additionalUserInfo?.profile?.lastName;
      this.obj.userid = this.User?.user?.uid ? this.User.user.uid : '';
      this.obj.loginuser = this.User?.user?.email ? this.User.user.email : '';
    } else if (this.availityUser?.firstName && this.availityUser?.lastName) {
      this.AdminName = this.availityUser?.firstName + " " + this.availityUser?.lastName;
      this.obj.userid = this.availityUserId ?? '';
      this.obj.loginuser = this.availityUser?.Email ? this.availityUser.Email : '';
    } else if(firebaseuser?.email) {
      this.AdminName = firebaseuser?.email ? firebaseuser.email : '';
      this.obj.userid = firebaseuser?.uid ? firebaseuser.uid : '';
      this.obj.loginuser = firebaseuser?.email ? firebaseuser.email : '';
    } else {
      this.AdminName = 'null';
      this.obj.userid = 'null';
      this.obj.loginuser = 'null';
    }
    this.obj.userfullname = this.AdminName ? this.AdminName : '';
    let impersonatedUser = this.commonService.getLocalStorage('ImpersonateUser');
    this.obj.impersonatedusername = (impersonatedUser.first_name || impersonatedUser.last_name) ? (impersonatedUser.first_name ? impersonatedUser.first_name : '') + " " + (impersonatedUser.last_name ? impersonatedUser.last_name : '') : '';
    this.obj.impersonateduserid = impersonatedUser?.po_business_id ? impersonatedUser.po_business_id : '';
    if (para.message != undefined && para.module != undefined && para.screen != undefined && para.useraction != undefined && para.transactionid != undefined) {
      this.obj.module = para.module;
      this.obj.message = para.message;
      this.obj.useraction = para.useraction;
      this.obj.screen = para.screen;
      this.obj.transactionid = para.transactionid;
    }
    else {
      this.obj.message = para;
      this.obj.useraction = "LOGIN";
      this.obj.screen = '';
      this.obj.module = ''
    }
    this.http.post<any>(environment.host + "audit/v1/WriteAuditLog", this.obj).subscribe(res => {
    })
  }
}
